@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
    height: 100%;
}

/* tippy.js modifications */
.tippy-box {
    background-color: #808CFA !important;
}
.tippy-arrow {
    color: #808CFA !important;
}
.tippy-content {
    padding: 7px 15px !important;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 1.8em;
}

/* Tailwind Form Plugin (app customisations) */
.form-checkbox:checked {
    /* https://yoksel.github.io/url-encoder/ */
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='%23251161' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72 3.1l-6.903 7.47L3.28 7.827a.298.298 0 00-.445 0l-.743.803a.36.36 0 000 .482l3.502 3.79a.298.298 0 00.445 0l7.869-8.516a.361.361 0 000-.482l-.742-.803a.298.298 0 00-.446 0z' /%3E%3C/svg%3E");
}
.form-checkbox:checked:hover, .form-checkbox:checked:focus, .form-radio:checked:hover, .form-radio:checked:focus {
    border-color: #251261;
    background-color: transparent;
}

.form-checkbox:disabled:checked {
    /* https://yoksel.github.io/url-encoder/ */
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='%23797EA7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72 3.1l-6.903 7.47L3.28 7.827a.298.298 0 00-.445 0l-.743.803a.36.36 0 000 .482l3.502 3.79a.298.298 0 00.445 0l7.869-8.516a.361.361 0 000-.482l-.742-.803a.298.298 0 00-.446 0z' /%3E%3C/svg%3E");
}

.form-checkbox:disabled:checked:hover, .form-checkbox:disabled:checked:focus, .form-radio:disabled:checked:hover, .form-radio:disabled:checked:focus {
    border-color: transparent;
    background-color: #BCBED3;
}

/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('Fonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Fonts/poppins-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url('Fonts/poppins-v15-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Fonts/poppins-v15-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('Fonts/poppins-v15-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Fonts/poppins-v15-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('Fonts/poppins-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('Fonts/poppins-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}